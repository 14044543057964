* {
    margin: 0;
  }
  
  .uploadpromise__banner {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .uploadpromise__content {
    align-items: center;
    text-align: center;
    padding: 80px;
    width: auto;
    display: flex;
    flex-direction: column;
    
  }
  
  .uploadpromise__content h1 {
    color: white;
    font-size: 2.4rem;
    padding-top: 20px;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .uploadpromise__banner__button {
    padding: 10px 20px;
    margin: 20px 0;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    background-color: #2580CC;
    font-weight: 300;
    border: none;
    cursor: pointer;
    transition: 0.5s;
  
  }
  
  .uploadpromise__banner__button:hover {
    background-color: #2556cc;
    font-weight: 500;
  }
  
  .uploadpromise__back {
    display: flex;
    padding-bottom: 20px;
    opacity: 0.8;
    align-items: center;
  }
  
  .uploadpromise__back__img {
    filter: invert(1);
  }
  
  .uploadpromise__back__msg {
    color: white;
    font-size: 1rem;
  }
  
  .uploadpromise__body {
    padding: 80px;
    text-align: left;
    line-height: 1.8rem;
  }
  
  .uploadpromise__body__definition {
    text-align: center;
    padding: 0 150px;
  }
  
  .uploadpromise__body__definition h2 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  
  .uploadpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.5rem;
    font-weight: normal;
  }

  .uploadpromise__datos {
    padding: 0;
  }

  .uploadpromise__datos__form {
    width: 800px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
  
  .uploadpromise__datos__ul__hor {
    display: flex;
    list-style-type: none;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    
  }
  
  .uploadpromise__datos__ul__hor li {
    margin: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    align-items: center;
  }
  
  .uploadpromise__datos__ul__hor li input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    opacity: 0.8;
    color: black;
    font-size: 1rem;
    text-align: center;
    border-color: #111;
  }

  .uploadpromise__datos__ul__hor li input::placeholder {
    padding: 0 20px;
  }
  
  .uploadpromise__datos__ul__ver {
    display: flex;
    list-style-type: none;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }
  
  .uploadpromise__datos__ul__ver li {
    margin: 0 10px;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    align-items: center;
  }
  
  .uploadpromise__datos__ul__ver li input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    opacity: 0.8;
    color: black;
    font-size: 1rem;
    text-align: center;
    border-color: #111;
  }

  .uploadpromise__datos__ul__ver li input::placeholder {
    padding: 0 20px;
  }

  .uploadpromise__enviar {
    padding: 15px;
    background-color: #2580CC;
    cursor: pointer;
    font-weight: 500;
    border-radius: 10px;
    width: 800px;
    margin: 0 auto;
    font-size: 1.2rem;
    border: none;
    display: flex;
    justify-content: center;
    color: white;
    transition: all 0.5s ease;

  }
  
  .uploadpromise__enviar:hover {
    background-color: #2556cc;
  }
  
  .uploadpromise__contact {
    width: 600px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
  
  /* Phones and Tablet*/
  @media (max-width: 600px) {
    .uploadpromise__banner {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    .uploadpromise__content {
      align-items: center;
      text-align: center;
      padding: 20px 0;
      width: auto;
      display: flex;
      flex-direction: column;
      
    }
    
    .uploadpromise__content h1 {
      color: white;
      font-size: 2.4rem;
      padding-top: 20px;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    
    .uploadpromise__banner__button {
      padding: 10px 20px;
      margin: 20px 0;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
      background-color: #7c51a1;
      font-weight: 300;
      border: none;
      cursor: pointer;
      transition: 0.5s;
    
    }
    
    .uploadpromise__banner__button:hover {
      background-color: grey;
      font-weight: 500;
    }
    
    .uploadpromise__back {
      display: flex;
      padding-bottom: 20px;
      opacity: 0.8;
      align-items: center;
    }
    
    .uploadpromise__back__img {
      filter: invert(1);
    }
    
    .uploadpromise__back__msg {
      color: white;
      font-size: 1rem;
    }
    
    .uploadpromise__body {
      padding: 20px;
      text-align: left;
      line-height: 1.8rem;
    }
    
    .uploadpromise__body__definition {
      text-align: center;
      padding: 0 150px;
    }
    
    .uploadpromise__body__definition h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    
    .uploadpromise__body__definition h3 {
      padding: 40px 0;
      font-size: 1.5rem;
      font-weight: normal;
    }
  
    .uploadpromise__datos {
      padding: 0;
    }
  
    .uploadpromise__datos__form {
      width: auto;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
    
    .uploadpromise__datos__ul__hor {
      display: flex;
      list-style-type: none;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      flex-direction: column;
    }
    
    .uploadpromise__datos__ul__hor li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__hor li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__hor li input::placeholder {
      padding: 0 20px;
    }
    
    .uploadpromise__datos__ul__ver {
      display: flex;
      list-style-type: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
    }
    
    .uploadpromise__datos__ul__ver li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__ver li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__ver li input::placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__enviar {
      padding: 15px;
      background-color: #8c63aa;
      cursor: pointer;
      font-weight: 500;
      border-radius: 10px;
      width: auto;
      margin: 0 auto;
      font-size: 1.2rem;
      border: none;
      display: flex;
      justify-content: center;
      color: white;
      transition: all 0.5s ease;
  
    }
    
    .uploadpromise__enviar:hover {
      background-color: #7c51a1;
    }
    
    .uploadpromise__contact {
      width: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      padding: 50px 0;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  
  /* Tablet and small laptop */
  @media (max-width: 768px) {
    .uploadpromise__banner {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    .uploadpromise__content {
      align-items: center;
      text-align: center;
      padding: 20px 0;
      width: auto;
      display: flex;
      flex-direction: column;
      
    }
    
    .uploadpromise__content h1 {
      color: white;
      font-size: 2.4rem;
      padding-top: 20px;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    
    .uploadpromise__banner__button {
      padding: 10px 20px;
      margin: 20px 0;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
      background-color: #7c51a1;
      font-weight: 300;
      border: none;
      cursor: pointer;
      transition: 0.5s;
    
    }
    
    .uploadpromise__banner__button:hover {
      background-color: grey;
      font-weight: 500;
    }
    
    .uploadpromise__back {
      display: flex;
      padding-bottom: 20px;
      opacity: 0.8;
      align-items: center;
    }
    
    .uploadpromise__back__img {
      filter: invert(1);
    }
    
    .uploadpromise__back__msg {
      color: white;
      font-size: 1rem;
    }
    
    .uploadpromise__body {
      padding: 20px;
      text-align: left;
      line-height: 1.8rem;
    }
    
    .uploadpromise__body__definition {
      text-align: center;
      padding: 0 150px;
    }
    
    .uploadpromise__body__definition h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    
    .uploadpromise__body__definition h3 {
      padding: 40px 0;
      font-size: 1.5rem;
      font-weight: normal;
    }
  
    .uploadpromise__datos {
      padding: 0;
    }
  
    .uploadpromise__datos__form {
      width: auto;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
    
    .uploadpromise__datos__ul__hor {
      display: flex;
      list-style-type: none;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      flex-direction: column;
    }
    
    .uploadpromise__datos__ul__hor li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__hor li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__hor li input::placeholder {
      padding: 0 20px;
    }
    
    .uploadpromise__datos__ul__ver {
      display: flex;
      list-style-type: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
    }
    
    .uploadpromise__datos__ul__ver li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__ver li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__ver li input::placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__enviar {
      padding: 15px;
      background-color: #8c63aa;
      cursor: pointer;
      font-weight: 500;
      border-radius: 10px;
      width: auto;
      margin: 0 auto;
      font-size: 1.2rem;
      border: none;
      display: flex;
      justify-content: center;
      color: white;
      transition: all 0.5s ease;
  
    }
    
    .uploadpromise__enviar:hover {
      background-color: #7c51a1;
    }
    
    .uploadpromise__contact {
      width: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      padding: 50px 0;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  
  /* Laptop */
  @media (max-width: 992px) {
    .uploadpromise__banner {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    .uploadpromise__content {
      align-items: center;
      text-align: center;
      padding: 20px 0;
      width: auto;
      display: flex;
      flex-direction: column;
      
    }
    
    .uploadpromise__content h1 {
      color: white;
      font-size: 2.4rem;
      padding-top: 20px;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    
    .uploadpromise__banner__button {
      padding: 10px 20px;
      margin: 20px 0;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
      background-color: #7c51a1;
      font-weight: 300;
      border: none;
      cursor: pointer;
      transition: 0.5s;
    
    }
    
    .uploadpromise__banner__button:hover {
      background-color: grey;
      font-weight: 500;
    }
    
    .uploadpromise__back {
      display: flex;
      padding-bottom: 20px;
      opacity: 0.8;
      align-items: center;
    }
    
    .uploadpromise__back__img {
      filter: invert(1);
    }
    
    .uploadpromise__back__msg {
      color: white;
      font-size: 1rem;
    }
    
    .uploadpromise__body {
      padding: 20px;
      text-align: left;
      line-height: 1.8rem;
    }
    
    .uploadpromise__body__definition {
      text-align: center;
      padding: 0 150px;
    }
    
    .uploadpromise__body__definition h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    
    .uploadpromise__body__definition h3 {
      padding: 40px 0;
      font-size: 1.5rem;
      font-weight: normal;
    }
  
    .uploadpromise__datos {
      padding: 0;
    }
  
    .uploadpromise__datos__form {
      width: auto;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
    
    .uploadpromise__datos__ul__hor {
      display: flex;
      list-style-type: none;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      flex-direction: column;
    }
    
    .uploadpromise__datos__ul__hor li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__hor li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__hor li input::placeholder {
      padding: 0 20px;
    }
    
    .uploadpromise__datos__ul__ver {
      display: flex;
      list-style-type: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
    }
    
    .uploadpromise__datos__ul__ver li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__ver li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__ver li input::placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__enviar {
      padding: 15px;
      background-color: #8c63aa;
      cursor: pointer;
      font-weight: 500;
      border-radius: 10px;
      width: auto;
      margin: 0 auto;
      font-size: 1.2rem;
      border: none;
      display: flex;
      justify-content: center;
      color: white;
      transition: all 0.5s ease;
  
    }
    
    .uploadpromise__enviar:hover {
      background-color: #7c51a1;
    }
    
    .uploadpromise__contact {
      width: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      padding: 50px 0;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  
  /* Desktop */
  @media (max-width: 1200px) {
  }
  