.nav {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px 20px 0px;
  height: auto;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background-color: #111;
  opacity: 1;
}

.nav__contents {
  display: flex;
  margin: 0 40px;
  justify-content: space-between;
}

.nav__logo {
  height: fit-content;
  width: 120px;
  padding-top: 5px;
  padding-right: 20px;
  cursor: pointer;
}

.nav__buttons a > button {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

.nav__buttons a > button:hover {
  background-color: #2556cc;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .nav {
    height: auto;
    background-color: rgba(17, 17, 17, 1);
  }

  .nav__contents {
    display: grid;
    flex-direction: column;
    margin: 0 40px;
    justify-content: center;
  }

  .nav__logo {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 0 20px 0;
    cursor: pointer;
  }

  .nav__buttons a > button {
    
    margin: 10px 0;
    
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
