.banner {
  position: relative;
  width: 100%;
  height: auto;
  color: #fff;
}

.banner__frame {
  display: flex;
  object-fit: contain;
}

.banner__contents {
  margin: 100px auto;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.banner__contents h1 {
  color: white;
  font-weight: normal;
}

.banner__options {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.banner__options__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: normal;
  border-radius: 0.8vw;
  padding: 1rem 2rem;
  margin: 0 1rem;
  font-size: 1.2rem;
  background-color: rgba(51, 51, 51, 0.7);
  width: 200px;
  height: 200px;
}

.banner__options__button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.5s;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .banner__contents {
    margin: 80px auto;
    padding: 40px;
    justify-content: center;
    align-items: center;
  }

  .banner__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }

  .banner__options__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: normal;
    border-radius: 0.8vw;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    background-color: rgba(51, 51, 51, 0.7);
    width: 200px;
    height: 200px;
  }

  .banner__options__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.5s;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .banner__contents {
    margin: 80px auto;
    padding: 40px;
    justify-content: center;
    align-items: center;
  }

  .banner__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }

  .banner__options__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: normal;
    border-radius: 0.8vw;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    background-color: rgba(51, 51, 51, 0.7);
    width: 200px;
    height: 200px;
  }

  .banner__options__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.5s;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .banner__contents {
    margin: 80px auto;
    padding: 40px;
    justify-content: center;
    align-items: center;
  }

  .banner__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }

  .banner__options__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: normal;
    border-radius: 0.8vw;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    background-color: rgba(51, 51, 51, 0.7);
    width: 200px;
    height: 200px;
  }

  .banner__options__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.5s;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}
