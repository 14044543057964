* {
  margin: 0;
  color: black;
}

.privacy {
  padding: 80px;
  width: auto;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: lighter;
  text-decoration: none;
}

.privacy__back {
  display: flex;
  align-items: center;
}

.privacy h1 {
  padding: 40px 0;
}

.privacy h2 {
  padding: 20px 0;
  font-weight: bold;
}

.privacy ul {
  padding: 20px 0;
}

.privacy ul li {
  font-size: 1.2rem;
  list-style-type: none;
  font-weight: lighter;
}

.privacy ul li > span {
  text-decoration-line: underline;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .privacy {
    padding: 40px;
    width: auto;
    text-align: left;
    line-height: 2.4rem;
    font-size: 1.4rem;
    font-weight: lighter;
    text-decoration: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
