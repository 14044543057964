* {
  margin: 0;
}

.creatorpromise__banner {
  position: relative;
  width: 100%;
  height: auto;
}

.creatorpromise__content {
  align-items: left;
  text-align: left;
  padding: 80px;
  width: 30%;
}

.creatorpromise__content h1 {
  color: white;
  font-size: 2.4rem;
  font-weight: 400;
  padding-top: 20px;
}

.creatorpromise__banner__button {
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 300;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

.creatorpromise__banner__button:hover {
  background-color: #2556cc;
  font-weight: 500;
}

.creatorpromise__back {
  display: flex;
  padding-bottom: 20px;
  opacity: 0.8;
  align-items: center;
}

.creatorpromise__back__img {
  filter: invert(1);
}

.creatorpromise__back__msg {
  color: white;
  font-size: 1rem;
}

.creatorpromise__body {
  padding: 80px;
  text-align: left;
  line-height: 1.8rem;
}

.creatorpromise__body__definition {
  text-align: center;
  padding: 0 150px;
}

.creatorpromise__body__definition h2 {
  font-size: 2rem;
  line-height: 2.4rem;
}

.creatorpromise__body__definition h3 {
  padding: 40px 0;
  font-size: 1.5rem;
  font-weight: normal;
}

.creatorpromise__body__offer {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__offer div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__offer div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__offer__img img {
  width: 250px;
  margin-left: 80px;
  border-radius: 20px;
}

.creatorpromise__body__followers {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__followers div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__followers div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__followers__img img {
  width: 250px;
  margin-right: 80px;
  border-radius: 20px;
}

.creatorpromise__body__together {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__together div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__together div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__together__img img {
  width: 250px;
  margin-left: 80px;
  border-radius: 20px;
}

.creatorpromise__body__expectation {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__expectation div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__expectation div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__expectation__img img {
  width: 250px;
  margin-right: 80px;
  border-radius: 20px;
}

.creatorpromise__body__closure {
  text-align: center;
  padding: 80px;
}

.creatorpromise__body__closure h4 {
  font-size: 1.8rem;
}

.creatorpromise__body__closure p {
  font-size: 1.5rem;
  font-weight: normal;
  padding: 20px 0;
}

.creatorpromise__contact {
  width: 600px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
  font-weight: normal;
  font-size: 1.2rem;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .creatorpromise__banner {
    position: relative;
    width: 100%;
    height: auto;
  }

  .creatorpromise__content {
    justify-content: center;
    text-align: center;
    padding: 20px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }

  .creatorpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    text-align: center;
  }

  .creatorpromise__banner__button {
    padding: 10px 20px;
    margin: 20px 0;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    background-color: #7c51a1;
    font-weight: 300;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .creatorpromise__body__definition {
    text-align: center;
    padding: 0 20px;
  }

  .creatorpromise__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .creatorpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .creatorpromise__body {
    padding: 0;
    text-align: center;
    line-height: 1.8rem;
  }

  .creatorpromise__body__offer {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__offer__img img {
    display: none;
  }

  .creatorpromise__body__followers {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__followers__img img {
    display: none;
  }

  .creatorpromise__body__together__img img {
    display: none;
  }

  .creatorpromise__body__together {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__expectation {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__expectation__img img {
    display: none;
  }

  .creatorpromise__contact {
    width: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .creatorpromise__content {
    align-items: left;
    text-align: left;
    padding: 80px;
    width: 55%;
  }

  .creatorpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
  }

  .creatorpromise__body__definition {
    text-align: center;
    padding: 0 50px;
  }

  .creatorpromise__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .creatorpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .creatorpromise__body {
    padding: 60px 20px;
    text-align: left;
    line-height: 1.8rem;
  }

  .creatorpromise__body__offer {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__offer__img img {
    display: none;
  }

  .creatorpromise__body__followers {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__followers__img img {
    display: none;
  }

  .creatorpromise__body__together__img img {
    display: none;
  }

  .creatorpromise__body__together {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation__img img {
    display: none;
  }

  .creatorpromise__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .creatorpromise__content {
    align-items: left;
    text-align: left;
    padding: 80px;
    width: 50%;
  }

  .creatorpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
  }

  .creatorpromise__body__definition {
    text-align: center;
    padding: 0 50px;
  }

  .creatorpromise__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .creatorpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .creatorpromise__body {
    padding: 60px 20px;
    text-align: left;
    line-height: 1.8rem;
  }

  .creatorpromise__body__offer {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__offer__img img {
    display: none;
  }

  .creatorpromise__body__followers {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__followers__img img {
    display: none;
  }

  .creatorpromise__body__together__img img {
    display: none;
  }

  .creatorpromise__body__together {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation__img img {
    display: none;
  }

  .creatorpromise__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}
