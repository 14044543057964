* {
  margin: 0;
}

.mediacenter {
  padding: 80px;
  width: auto;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: lighter;
  text-decoration: none;
}

.mediacenter__back {
  display: flex;
  align-items: center;
}

.mediacenter__msg {
  padding: 60px 80px 20px 80px;
  text-align: center;
}

.mediacenter__msg h1 {
  padding-bottom: 20px;
}

.mediacenter__images {
  padding: 60px;
}

.mediacenter__images__item {
  padding-bottom: 80px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.mediacenter__images__item img {
  width: 70%;
  border-radius: 0;
  transition: 0.5s;
  padding: 20px 0;
}

.mediacenter__images__item img:hover {
  transition: 0.5s;
  transform: scale(1.05);
}

.mediacenter__images__item p {
  font-size: 1.8rem;
  padding: 10px 0;
  font-weight: bold;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .mediacenter__msg {
    padding: 80px 20px 20px 20px;
    text-align: center;
  }

  .mediacenter__msg h1 {
    padding-bottom: 20px;
  }

  .mediacenter__images {
    padding: 0px;
  }

  .mediacenter__images__item {
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  .mediacenter__images__item img {
    width: 100%;
    border-radius: 0;
    transition: 0.5s;
    padding: 20px 0;
  }

  .mediacenter__images__item img:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .mediacenter__msg {
    padding: 80px 20px 20px 20px;
    text-align: center;
  }

  .mediacenter__msg h1 {
    padding-bottom: 20px;
  }

  .mediacenter__images {
    padding: 0px;
  }

  .mediacenter__images__item {
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  .mediacenter__images__item img {
    width: 100%;
    border-radius: 0;
    transition: 0.5s;
    padding: 20px 0;
  }

  .mediacenter__images__item img:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
