.homescreen {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  /* background-image: linear-gradient(to right, black, #111); */
}

.homeScreen__faq {
  width: 80%;
  padding: 100px 80px 140px 80px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.homeScreen__faq > h3 {
  padding-bottom: 40px;
  text-align: center;
}

.homescreen__contact {
  width: 600px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
  font-weight: normal;
  font-size: 1.2rem;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .homeScreen__faq {
    width: 80%;
    padding: 60px 0 80px 0;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .homeScreen__faq > h3 {
    padding-bottom: 60px;
    text-align: center;
  }

  .homescreen__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .homeScreen__faq {
    width: 80%;
    padding: 60px 0 80px 0;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .homeScreen__faq > h3 {
    padding-bottom: 60px;
    text-align: center;
  }

  .homescreen__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}
