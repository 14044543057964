* {
  padding: 0;
  margin: 0;
}

.app {
  text-align: center;
  width: 100%;
  min-height: 100vh;
}
