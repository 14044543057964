.box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.iconLink {
  margin: 0 1rem;
}
