@import url(https://fonts.googleapis.com/css?family=Rubik);
body {
  margin: 0;
  font-family: "Rubik", sans-serif, lighter, normal, bold, bolder;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

.app {
  text-align: center;
  width: 100%;
  min-height: 100vh;
}

.homescreen {
  text-align: center;
  width: 100%;
  min-height: 100vh;
  /* background-image: linear-gradient(to right, black, #111); */
}

.homeScreen__faq {
  width: 80%;
  padding: 100px 80px 140px 80px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.8rem;
}

.homeScreen__faq > h3 {
  padding-bottom: 40px;
  text-align: center;
}

.homescreen__contact {
  width: 600px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
  font-weight: normal;
  font-size: 1.2rem;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .homeScreen__faq {
    width: 80%;
    padding: 60px 0 80px 0;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .homeScreen__faq > h3 {
    padding-bottom: 60px;
    text-align: center;
  }

  .homescreen__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .homeScreen__faq {
    width: 80%;
    padding: 60px 0 80px 0;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  .homeScreen__faq > h3 {
    padding-bottom: 60px;
    text-align: center;
  }

  .homescreen__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0px 20px 0px;
  height: auto;
  z-index: 1000;
  color: white;
  background-color: rgba(17, 17, 17, 1);

  /* Animaciones */
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.nav__black {
  background-color: #111;
  opacity: 1;
}

.nav__contents {
  display: flex;
  margin: 0 40px;
  justify-content: space-between;
}

.nav__logo {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 120px;
  padding-top: 5px;
  padding-right: 20px;
  cursor: pointer;
}

.nav__buttons a > button {
  padding: 10px 20px;
  margin: 0 5px;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 400;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

.nav__buttons a > button:hover {
  background-color: #2556cc;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.nav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.nav {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .nav {
    height: auto;
    background-color: rgba(17, 17, 17, 1);
  }

  .nav__contents {
    display: grid;
    flex-direction: column;
    margin: 0 40px;
    justify-content: center;
  }

  .nav__logo {
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 0 20px 0;
    cursor: pointer;
  }

  .nav__buttons a > button {
    
    margin: 10px 0;
    
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

.banner {
  position: relative;
  width: 100%;
  height: auto;
  color: #fff;
}

.banner__frame {
  display: flex;
  object-fit: contain;
}

.banner__contents {
  margin: 100px auto;
  padding: 40px;
  justify-content: center;
  align-items: center;
}

.banner__contents h1 {
  color: white;
  font-weight: normal;
}

.banner__options {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}

.banner__options__button {
  cursor: pointer;
  color: #fff;
  outline: none;
  border: none;
  font-weight: normal;
  border-radius: 0.8vw;
  padding: 1rem 2rem;
  margin: 0 1rem;
  font-size: 1.2rem;
  background-color: rgba(51, 51, 51, 0.7);
  width: 200px;
  height: 200px;
}

.banner__options__button:hover {
  color: #000;
  background-color: #e6e6e6;
  transition: all 0.5s;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .banner__contents {
    margin: 80px auto;
    padding: 40px;
    justify-content: center;
    align-items: center;
  }

  .banner__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }

  .banner__options__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: normal;
    border-radius: 0.8vw;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    background-color: rgba(51, 51, 51, 0.7);
    width: 200px;
    height: 200px;
  }

  .banner__options__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.5s;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .banner__contents {
    margin: 80px auto;
    padding: 40px;
    justify-content: center;
    align-items: center;
  }

  .banner__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }

  .banner__options__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: normal;
    border-radius: 0.8vw;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    background-color: rgba(51, 51, 51, 0.7);
    width: 200px;
    height: 200px;
  }

  .banner__options__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.5s;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .banner__contents {
    margin: 80px auto;
    padding: 40px;
    justify-content: center;
    align-items: center;
  }

  .banner__options {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
  }

  .banner__options__button {
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: normal;
    border-radius: 0.8vw;
    padding: 1rem 2rem;
    margin: 0 1rem;
    font-size: 1.2rem;
    background-color: rgba(51, 51, 51, 0.7);
    width: 200px;
    height: 200px;
  }

  .banner__options__button:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.5s;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.iconLink {
  margin: 0 1rem;
}

.copyright {
  margin: 0;
  color: #111;
  opacity: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  font-size: 0.9rem;
  background-color: whitesmoke;
  z-index: 99;
}

.copyright > p {
  padding: 5px;
}

* {
  margin: 0;
  color: black;
}

.termsofuse {
  padding: 80px;
  width: auto;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: lighter;
  text-decoration: none;
}

.termsofuse__back {
  display: flex;
  align-items: center;
}

.termsofuse h1 {
  padding: 40px 0;
}

.termsofuse h2 {
  padding: 20px 0;
  font-weight: bold;
}

.termsofuse ul {
  padding: 20px 0;
}

.termsofuse ul li {
  font-size: 1.2rem;
  list-style-type: none;
  font-weight: lighter;
}

.termsofuse ul li > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .termsofuse {
    padding: 40px;
    width: auto;
    text-align: left;
    line-height: 2.4rem;
    font-size: 1.4rem;
    font-weight: lighter;
    text-decoration: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

* {
  margin: 0;
  color: black;
}

.privacy {
  padding: 80px;
  width: auto;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: lighter;
  text-decoration: none;
}

.privacy__back {
  display: flex;
  align-items: center;
}

.privacy h1 {
  padding: 40px 0;
}

.privacy h2 {
  padding: 20px 0;
  font-weight: bold;
}

.privacy ul {
  padding: 20px 0;
}

.privacy ul li {
  font-size: 1.2rem;
  list-style-type: none;
  font-weight: lighter;
}

.privacy ul li > span {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .privacy {
    padding: 40px;
    width: auto;
    text-align: left;
    line-height: 2.4rem;
    font-size: 1.4rem;
    font-weight: lighter;
    text-decoration: none;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

* {
  margin: 0;
}

.mediacenter {
  padding: 80px;
  width: auto;
  text-align: left;
  line-height: 2.4rem;
  font-size: 1.4rem;
  font-weight: lighter;
  text-decoration: none;
}

.mediacenter__back {
  display: flex;
  align-items: center;
}

.mediacenter__msg {
  padding: 60px 80px 20px 80px;
  text-align: center;
}

.mediacenter__msg h1 {
  padding-bottom: 20px;
}

.mediacenter__images {
  padding: 60px;
}

.mediacenter__images__item {
  padding-bottom: 80px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.mediacenter__images__item img {
  width: 70%;
  border-radius: 0;
  transition: 0.5s;
  padding: 20px 0;
}

.mediacenter__images__item img:hover {
  transition: 0.5s;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.mediacenter__images__item p {
  font-size: 1.8rem;
  padding: 10px 0;
  font-weight: bold;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .mediacenter__msg {
    padding: 80px 20px 20px 20px;
    text-align: center;
  }

  .mediacenter__msg h1 {
    padding-bottom: 20px;
  }

  .mediacenter__images {
    padding: 0px;
  }

  .mediacenter__images__item {
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  .mediacenter__images__item img {
    width: 100%;
    border-radius: 0;
    transition: 0.5s;
    padding: 20px 0;
  }

  .mediacenter__images__item img:hover {
    transition: 0.5s;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .mediacenter__msg {
    padding: 80px 20px 20px 20px;
    text-align: center;
  }

  .mediacenter__msg h1 {
    padding-bottom: 20px;
  }

  .mediacenter__images {
    padding: 0px;
  }

  .mediacenter__images__item {
    padding-bottom: 80px;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  .mediacenter__images__item img {
    width: 100%;
    border-radius: 0;
    transition: 0.5s;
    padding: 20px 0;
  }

  .mediacenter__images__item img:hover {
    transition: 0.5s;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}

/* Laptop */
@media (max-width: 992px) {
}

/* Desktop */
@media (max-width: 1200px) {
}

* {
  margin: 0;
}

.creatorpromise__banner {
  position: relative;
  width: 100%;
  height: auto;
}

.creatorpromise__content {
  align-items: left;
  text-align: left;
  padding: 80px;
  width: 30%;
}

.creatorpromise__content h1 {
  color: white;
  font-size: 2.4rem;
  font-weight: 400;
  padding-top: 20px;
}

.creatorpromise__banner__button {
  padding: 10px 20px;
  margin: 20px 0;
  font-size: 1rem;
  color: #fff;
  border-radius: 5px;
  background-color: #2580CC;
  font-weight: 300;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

.creatorpromise__banner__button:hover {
  background-color: #2556cc;
  font-weight: 500;
}

.creatorpromise__back {
  display: flex;
  padding-bottom: 20px;
  opacity: 0.8;
  align-items: center;
}

.creatorpromise__back__img {
  -webkit-filter: invert(1);
          filter: invert(1);
}

.creatorpromise__back__msg {
  color: white;
  font-size: 1rem;
}

.creatorpromise__body {
  padding: 80px;
  text-align: left;
  line-height: 1.8rem;
}

.creatorpromise__body__definition {
  text-align: center;
  padding: 0 150px;
}

.creatorpromise__body__definition h2 {
  font-size: 2rem;
  line-height: 2.4rem;
}

.creatorpromise__body__definition h3 {
  padding: 40px 0;
  font-size: 1.5rem;
  font-weight: normal;
}

.creatorpromise__body__offer {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__offer div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__offer div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__offer__img img {
  width: 250px;
  margin-left: 80px;
  border-radius: 20px;
}

.creatorpromise__body__followers {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__followers div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__followers div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__followers__img img {
  width: 250px;
  margin-right: 80px;
  border-radius: 20px;
}

.creatorpromise__body__together {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__together div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__together div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__together__img img {
  width: 250px;
  margin-left: 80px;
  border-radius: 20px;
}

.creatorpromise__body__expectation {
  display: flex;
  padding: 80px;
}

.creatorpromise__body__expectation div p {
  font-size: 1.5rem;
  padding: 20px 0;
  font-weight: bold;
}

.creatorpromise__body__expectation div ul li {
  font-size: 1.2rem;
}

.creatorpromise__body__expectation__img img {
  width: 250px;
  margin-right: 80px;
  border-radius: 20px;
}

.creatorpromise__body__closure {
  text-align: center;
  padding: 80px;
}

.creatorpromise__body__closure h4 {
  font-size: 1.8rem;
}

.creatorpromise__body__closure p {
  font-size: 1.5rem;
  font-weight: normal;
  padding: 20px 0;
}

.creatorpromise__contact {
  width: 600px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 30px;
  font-weight: normal;
  font-size: 1.2rem;
}

/* Phones and Tablet*/
@media (max-width: 600px) {
  .creatorpromise__banner {
    position: relative;
    width: 100%;
    height: auto;
  }

  .creatorpromise__content {
    justify-content: center;
    text-align: center;
    padding: 20px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    flex-direction: column;
  }

  .creatorpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    text-align: center;
  }

  .creatorpromise__banner__button {
    padding: 10px 20px;
    margin: 20px 0;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    background-color: #7c51a1;
    font-weight: 300;
    border: none;
    cursor: pointer;
    transition: 0.5s;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .creatorpromise__body__definition {
    text-align: center;
    padding: 0 20px;
  }

  .creatorpromise__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .creatorpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .creatorpromise__body {
    padding: 0;
    text-align: center;
    line-height: 1.8rem;
  }

  .creatorpromise__body__offer {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__offer__img img {
    display: none;
  }

  .creatorpromise__body__followers {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__followers__img img {
    display: none;
  }

  .creatorpromise__body__together__img img {
    display: none;
  }

  .creatorpromise__body__together {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__expectation {
    display: flex;
    padding: 20px;
  }

  .creatorpromise__body__expectation__img img {
    display: none;
  }

  .creatorpromise__contact {
    width: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Tablet and small laptop */
@media (max-width: 768px) {
  .creatorpromise__content {
    align-items: left;
    text-align: left;
    padding: 80px;
    width: 55%;
  }

  .creatorpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
  }

  .creatorpromise__body__definition {
    text-align: center;
    padding: 0 50px;
  }

  .creatorpromise__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .creatorpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .creatorpromise__body {
    padding: 60px 20px;
    text-align: left;
    line-height: 1.8rem;
  }

  .creatorpromise__body__offer {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__offer__img img {
    display: none;
  }

  .creatorpromise__body__followers {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__followers__img img {
    display: none;
  }

  .creatorpromise__body__together__img img {
    display: none;
  }

  .creatorpromise__body__together {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation__img img {
    display: none;
  }

  .creatorpromise__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Laptop */
@media (max-width: 992px) {
  .creatorpromise__content {
    align-items: left;
    text-align: left;
    padding: 80px;
    width: 50%;
  }

  .creatorpromise__content h1 {
    color: white;
    font-size: 2rem;
    padding: 20px 0;
  }

  .creatorpromise__body__definition {
    text-align: center;
    padding: 0 50px;
  }

  .creatorpromise__body__definition h2 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .creatorpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.2rem;
    font-weight: normal;
  }

  .creatorpromise__body {
    padding: 60px 20px;
    text-align: left;
    line-height: 1.8rem;
  }

  .creatorpromise__body__offer {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__offer__img img {
    display: none;
  }

  .creatorpromise__body__followers {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__followers__img img {
    display: none;
  }

  .creatorpromise__body__together__img img {
    display: none;
  }

  .creatorpromise__body__together {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation {
    display: flex;
    padding: 40px 80px;
  }

  .creatorpromise__body__expectation__img img {
    display: none;
  }

  .creatorpromise__contact {
    width: 300px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
}

/* Desktop */
@media (max-width: 1200px) {
}

* {
    margin: 0;
  }
  
  .uploadpromise__banner {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .uploadpromise__content {
    align-items: center;
    text-align: center;
    padding: 80px;
    width: auto;
    display: flex;
    flex-direction: column;
    
  }
  
  .uploadpromise__content h1 {
    color: white;
    font-size: 2.4rem;
    padding-top: 20px;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  
  .uploadpromise__banner__button {
    padding: 10px 20px;
    margin: 20px 0;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    background-color: #2580CC;
    font-weight: 300;
    border: none;
    cursor: pointer;
    transition: 0.5s;
  
  }
  
  .uploadpromise__banner__button:hover {
    background-color: #2556cc;
    font-weight: 500;
  }
  
  .uploadpromise__back {
    display: flex;
    padding-bottom: 20px;
    opacity: 0.8;
    align-items: center;
  }
  
  .uploadpromise__back__img {
    -webkit-filter: invert(1);
            filter: invert(1);
  }
  
  .uploadpromise__back__msg {
    color: white;
    font-size: 1rem;
  }
  
  .uploadpromise__body {
    padding: 80px;
    text-align: left;
    line-height: 1.8rem;
  }
  
  .uploadpromise__body__definition {
    text-align: center;
    padding: 0 150px;
  }
  
  .uploadpromise__body__definition h2 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  
  .uploadpromise__body__definition h3 {
    padding: 40px 0;
    font-size: 1.5rem;
    font-weight: normal;
  }

  .uploadpromise__datos {
    padding: 0;
  }

  .uploadpromise__datos__form {
    width: 800px;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
  
  .uploadpromise__datos__ul__hor {
    display: flex;
    list-style-type: none;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    
  }
  
  .uploadpromise__datos__ul__hor li {
    margin: 0 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    align-items: center;
  }
  
  .uploadpromise__datos__ul__hor li input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    opacity: 0.8;
    color: black;
    font-size: 1rem;
    text-align: center;
    border-color: #111;
  }

  .uploadpromise__datos__ul__hor li input::-webkit-input-placeholder {
    padding: 0 20px;
  }

  .uploadpromise__datos__ul__hor li input:-ms-input-placeholder {
    padding: 0 20px;
  }

  .uploadpromise__datos__ul__hor li input::placeholder {
    padding: 0 20px;
  }
  
  .uploadpromise__datos__ul__ver {
    display: flex;
    list-style-type: none;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
  }
  
  .uploadpromise__datos__ul__ver li {
    margin: 0 10px;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    align-items: center;
  }
  
  .uploadpromise__datos__ul__ver li input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: white;
    opacity: 0.8;
    color: black;
    font-size: 1rem;
    text-align: center;
    border-color: #111;
  }

  .uploadpromise__datos__ul__ver li input::-webkit-input-placeholder {
    padding: 0 20px;
  }

  .uploadpromise__datos__ul__ver li input:-ms-input-placeholder {
    padding: 0 20px;
  }

  .uploadpromise__datos__ul__ver li input::placeholder {
    padding: 0 20px;
  }

  .uploadpromise__enviar {
    padding: 15px;
    background-color: #2580CC;
    cursor: pointer;
    font-weight: 500;
    border-radius: 10px;
    width: 800px;
    margin: 0 auto;
    font-size: 1.2rem;
    border: none;
    display: flex;
    justify-content: center;
    color: white;
    transition: all 0.5s ease;

  }
  
  .uploadpromise__enviar:hover {
    background-color: #2556cc;
  }
  
  .uploadpromise__contact {
    width: 600px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 30px;
    font-weight: normal;
    font-size: 1.2rem;
  }
  
  /* Phones and Tablet*/
  @media (max-width: 600px) {
    .uploadpromise__banner {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    .uploadpromise__content {
      align-items: center;
      text-align: center;
      padding: 20px 0;
      width: auto;
      display: flex;
      flex-direction: column;
      
    }
    
    .uploadpromise__content h1 {
      color: white;
      font-size: 2.4rem;
      padding-top: 20px;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    
    .uploadpromise__banner__button {
      padding: 10px 20px;
      margin: 20px 0;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
      background-color: #7c51a1;
      font-weight: 300;
      border: none;
      cursor: pointer;
      transition: 0.5s;
    
    }
    
    .uploadpromise__banner__button:hover {
      background-color: grey;
      font-weight: 500;
    }
    
    .uploadpromise__back {
      display: flex;
      padding-bottom: 20px;
      opacity: 0.8;
      align-items: center;
    }
    
    .uploadpromise__back__img {
      -webkit-filter: invert(1);
              filter: invert(1);
    }
    
    .uploadpromise__back__msg {
      color: white;
      font-size: 1rem;
    }
    
    .uploadpromise__body {
      padding: 20px;
      text-align: left;
      line-height: 1.8rem;
    }
    
    .uploadpromise__body__definition {
      text-align: center;
      padding: 0 150px;
    }
    
    .uploadpromise__body__definition h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    
    .uploadpromise__body__definition h3 {
      padding: 40px 0;
      font-size: 1.5rem;
      font-weight: normal;
    }
  
    .uploadpromise__datos {
      padding: 0;
    }
  
    .uploadpromise__datos__form {
      width: auto;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
    
    .uploadpromise__datos__ul__hor {
      display: flex;
      list-style-type: none;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      flex-direction: column;
    }
    
    .uploadpromise__datos__ul__hor li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__hor li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__hor li input::-webkit-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__hor li input:-ms-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__hor li input::placeholder {
      padding: 0 20px;
    }
    
    .uploadpromise__datos__ul__ver {
      display: flex;
      list-style-type: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
    }
    
    .uploadpromise__datos__ul__ver li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__ver li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__ver li input::-webkit-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__ver li input:-ms-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__ver li input::placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__enviar {
      padding: 15px;
      background-color: #8c63aa;
      cursor: pointer;
      font-weight: 500;
      border-radius: 10px;
      width: auto;
      margin: 0 auto;
      font-size: 1.2rem;
      border: none;
      display: flex;
      justify-content: center;
      color: white;
      transition: all 0.5s ease;
  
    }
    
    .uploadpromise__enviar:hover {
      background-color: #7c51a1;
    }
    
    .uploadpromise__contact {
      width: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      padding: 50px 0;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  
  /* Tablet and small laptop */
  @media (max-width: 768px) {
    .uploadpromise__banner {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    .uploadpromise__content {
      align-items: center;
      text-align: center;
      padding: 20px 0;
      width: auto;
      display: flex;
      flex-direction: column;
      
    }
    
    .uploadpromise__content h1 {
      color: white;
      font-size: 2.4rem;
      padding-top: 20px;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    
    .uploadpromise__banner__button {
      padding: 10px 20px;
      margin: 20px 0;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
      background-color: #7c51a1;
      font-weight: 300;
      border: none;
      cursor: pointer;
      transition: 0.5s;
    
    }
    
    .uploadpromise__banner__button:hover {
      background-color: grey;
      font-weight: 500;
    }
    
    .uploadpromise__back {
      display: flex;
      padding-bottom: 20px;
      opacity: 0.8;
      align-items: center;
    }
    
    .uploadpromise__back__img {
      -webkit-filter: invert(1);
              filter: invert(1);
    }
    
    .uploadpromise__back__msg {
      color: white;
      font-size: 1rem;
    }
    
    .uploadpromise__body {
      padding: 20px;
      text-align: left;
      line-height: 1.8rem;
    }
    
    .uploadpromise__body__definition {
      text-align: center;
      padding: 0 150px;
    }
    
    .uploadpromise__body__definition h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    
    .uploadpromise__body__definition h3 {
      padding: 40px 0;
      font-size: 1.5rem;
      font-weight: normal;
    }
  
    .uploadpromise__datos {
      padding: 0;
    }
  
    .uploadpromise__datos__form {
      width: auto;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
    
    .uploadpromise__datos__ul__hor {
      display: flex;
      list-style-type: none;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      flex-direction: column;
    }
    
    .uploadpromise__datos__ul__hor li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__hor li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__hor li input::-webkit-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__hor li input:-ms-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__hor li input::placeholder {
      padding: 0 20px;
    }
    
    .uploadpromise__datos__ul__ver {
      display: flex;
      list-style-type: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
    }
    
    .uploadpromise__datos__ul__ver li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__ver li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__ver li input::-webkit-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__ver li input:-ms-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__ver li input::placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__enviar {
      padding: 15px;
      background-color: #8c63aa;
      cursor: pointer;
      font-weight: 500;
      border-radius: 10px;
      width: auto;
      margin: 0 auto;
      font-size: 1.2rem;
      border: none;
      display: flex;
      justify-content: center;
      color: white;
      transition: all 0.5s ease;
  
    }
    
    .uploadpromise__enviar:hover {
      background-color: #7c51a1;
    }
    
    .uploadpromise__contact {
      width: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      padding: 50px 0;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  
  /* Laptop */
  @media (max-width: 992px) {
    .uploadpromise__banner {
      position: relative;
      width: 100%;
      height: auto;
    }
    
    .uploadpromise__content {
      align-items: center;
      text-align: center;
      padding: 20px 0;
      width: auto;
      display: flex;
      flex-direction: column;
      
    }
    
    .uploadpromise__content h1 {
      color: white;
      font-size: 2.4rem;
      padding-top: 20px;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    
    .uploadpromise__banner__button {
      padding: 10px 20px;
      margin: 20px 0;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
      background-color: #7c51a1;
      font-weight: 300;
      border: none;
      cursor: pointer;
      transition: 0.5s;
    
    }
    
    .uploadpromise__banner__button:hover {
      background-color: grey;
      font-weight: 500;
    }
    
    .uploadpromise__back {
      display: flex;
      padding-bottom: 20px;
      opacity: 0.8;
      align-items: center;
    }
    
    .uploadpromise__back__img {
      -webkit-filter: invert(1);
              filter: invert(1);
    }
    
    .uploadpromise__back__msg {
      color: white;
      font-size: 1rem;
    }
    
    .uploadpromise__body {
      padding: 20px;
      text-align: left;
      line-height: 1.8rem;
    }
    
    .uploadpromise__body__definition {
      text-align: center;
      padding: 0 150px;
    }
    
    .uploadpromise__body__definition h2 {
      font-size: 2rem;
      line-height: 2.4rem;
    }
    
    .uploadpromise__body__definition h3 {
      padding: 40px 0;
      font-size: 1.5rem;
      font-weight: normal;
    }
  
    .uploadpromise__datos {
      padding: 0;
    }
  
    .uploadpromise__datos__form {
      width: auto;
      margin: 0 auto;
      justify-content: center;
      text-align: center;
    }
    
    .uploadpromise__datos__ul__hor {
      display: flex;
      list-style-type: none;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      flex-direction: column;
    }
    
    .uploadpromise__datos__ul__hor li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__hor li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__hor li input::-webkit-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__hor li input:-ms-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__hor li input::placeholder {
      padding: 0 20px;
    }
    
    .uploadpromise__datos__ul__ver {
      display: flex;
      list-style-type: none;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
    }
    
    .uploadpromise__datos__ul__ver li {
      margin: 0 10px;
      width: auto;
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      align-items: center;
    }
    
    .uploadpromise__datos__ul__ver li input {
      width: 100%;
      height: 50px;
      border-radius: 10px;
      margin-bottom: 10px;
      background-color: white;
      opacity: 0.8;
      color: black;
      font-size: 1rem;
      text-align: center;
      border-color: #111;
    }
  
    .uploadpromise__datos__ul__ver li input::-webkit-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__ver li input:-ms-input-placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__datos__ul__ver li input::placeholder {
      padding: 0 20px;
    }
  
    .uploadpromise__enviar {
      padding: 15px;
      background-color: #8c63aa;
      cursor: pointer;
      font-weight: 500;
      border-radius: 10px;
      width: auto;
      margin: 0 auto;
      font-size: 1.2rem;
      border: none;
      display: flex;
      justify-content: center;
      color: white;
      transition: all 0.5s ease;
  
    }
    
    .uploadpromise__enviar:hover {
      background-color: #7c51a1;
    }
    
    .uploadpromise__contact {
      width: auto;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 0 auto;
      padding: 50px 0;
      font-weight: normal;
      font-size: 1.2rem;
    }
  }
  
  /* Desktop */
  @media (max-width: 1200px) {
  }
  
