.copyright {
  margin: 0;
  color: #111;
  opacity: 1;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  font-size: 0.9rem;
  background-color: whitesmoke;
  z-index: 99;
}

.copyright > p {
  padding: 5px;
}
